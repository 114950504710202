html, body {
    width: 100%;
    min-height: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    overflow-y: hidden;
}